var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{attrs:{"append-to-body":"","width":"1000px","title":"修改数量","visible":_vm.visible,"before-close":_vm.handleReturn,"close-on-click-modal":false,"close-on-press-escape":false},on:{"update:visible":function($event){_vm.visible=$event}}},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.detailsData,"max-height":"500px","border":""}},[_c('el-table-column',{attrs:{"label":"商品","prop":"good_name","width":"240"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"good"},[(!!row.good_picture)?_c('el-image',{staticStyle:{"width":"40px","height":"40px"},attrs:{"src":row.good_picture,"fit":"cover","preview-src-list":[row.good_picture]}}):_c('el-image',{staticStyle:{"width":"40px","height":"40px"},attrs:{"src":require('@/assets/img/logo-icon.jpeg'),"fit":"cover","preview-src-list":[require('@/assets/img/logo-icon.jpeg')]}}),_c('div',{staticClass:"good-info"},[_c('div',{staticStyle:{"color":"#595959","margin-bottom":"6px"}},[_vm._v(_vm._s(row.good_name))]),_c('div',[_vm._v("规格："+_vm._s(row.spec_name))]),_c('div',[_vm._v("商品ID："),_c('span',{staticStyle:{"color":"#595959"}},[_vm._v(_vm._s(row.good_id))])])])],1)]}}])}),_c('el-table-column',{attrs:{"prop":"price","label":"售价"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return [(_vm.open_price == 1)?_c('el-popover',{ref:("popoverDom0+" + $index),attrs:{"placement":"bottom","width":"300","trigger":"click"}},[_c('el-form',{staticClass:"staff-from",attrs:{"model":_vm.form}},[_c('el-form-item',{attrs:{"label":"修改售价","prop":"price"}},[_c('el-input',{attrs:{"autosize":"","placeholder":row.price,"size":"small"},on:{"input":function($event){_vm.form.price=_vm.form.price.match(/\d+(\.\d{0,2})?/) ? _vm.form.price.match(/\d+(\.\d{0,2})?/)[0] : ''}},model:{value:(_vm.form.price),callback:function ($$v) {_vm.$set(_vm.form, "price", $$v)},expression:"form.price"}},[_c('span',{attrs:{"slot":"suffix"},slot:"suffix"},[_vm._v("元")])])],1)],1),_c('el-row',{attrs:{"type":"flex","justify":"center"}},[_c('el-button',{attrs:{"type":"primary","loading":_vm.form.loading,"disabled":_vm.form.loading},on:{"click":function($event){return _vm.handlePrice(row,$index)}}},[_vm._v("确定")])],1),_c('el-button',{staticStyle:{"color":"#409eff","width":"98px","text-align":"left"},attrs:{"slot":"reference","type":"text"},slot:"reference"},[_c('span',[_vm._v("¥"+_vm._s(row.price)+"/"+_vm._s(row.spec_unit_name))]),_c('i',{staticClass:"el-icon-edit",staticStyle:{"margin-left":"10px"}})])],1):_c('span',[_vm._v("¥"+_vm._s(row.price)+"/"+_vm._s(row.spec_unit_name))])]}}])}),_c('el-table-column',{attrs:{"prop":"quantity","label":"数量","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" × "),_c('span',{staticStyle:{"font-weight":"bold","font-size":"16px"}},[_vm._v(_vm._s(row.quantity))])]}}])}),_c('el-table-column',{attrs:{"prop":"amount","label":"金额"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.amount)+"元")])]}}])}),_c('el-table-column',{attrs:{"prop":"remark","label":"备注","width":"120","show-overflow-tooltip":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return [(row.amount != 0)?[_c('el-popover',{ref:("popoverDom+" + $index),attrs:{"placement":"bottom","width":"300","trigger":"click"}},[_c('el-form',{staticClass:"staff-from",attrs:{"model":_vm.form}},[_c('el-form-item',{attrs:{"label":"备注","prop":"remark"}},[_c('el-input',{attrs:{"autosize":"","placeholder":"请输入（限50字）","size":"small","type":"textarea","maxlength":"50"},model:{value:(_vm.form.remark),callback:function ($$v) {_vm.$set(_vm.form, "remark", $$v)},expression:"form.remark"}})],1)],1),_c('el-row',{attrs:{"type":"flex","justify":"center"}},[_c('el-button',{attrs:{"type":"primary","loading":_vm.form.loading,"disabled":_vm.form.loading},on:{"click":function($event){return _vm.handleRemark(row,$index)}}},[_vm._v("确定")])],1),_c('el-button',{staticStyle:{"color":"#409eff","width":"98px","text-align":"left"},attrs:{"slot":"reference","type":"text"},slot:"reference"},[(!!row.remark)?_c('span',{staticStyle:{"width":"98px","display":"inline-block","overflow":"hidden"}},[_vm._v(_vm._s(row.remark))]):_c('i',{staticClass:"el-icon-edit-outline"})])],1)]:_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"num","label":"修改数量","width":"140px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.amount != 0)?[_c('el-input',{staticClass:"numWrapInput",attrs:{"size":"small","placeholder":"实际数量"},on:{"input":function($event){return _vm.numREG_EXP(row)}},model:{value:(row.num),callback:function ($$v) {_vm.$set(row, "num", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"row.num"}},[_c('el-button',{attrs:{"slot":"append","type":"primary","icon":"el-icon-check","circle":""},on:{"click":function($event){return _vm.handleNum(row)}},slot:"append"})],1)]:_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }